import UtilityService from '@/services/utility_service';
import { defineStore } from 'pinia'

export const useUtilities = defineStore('utilities', {
  state: () => ({
    sidebarCounts: {},
    organizationFeatures: {},
    userSettings: {},
    passwordChangeRequired: false,
    cashAdvanceEnabled: false,
    lendingEnabled: false,
    organizationKind: '',
    organizationType: '',
    isGovernment: false,
  }),

  actions: {
    async getSidebarParams() {
      try {
        const response = await UtilityService.getSidebarParams()
        this.sidebarCounts = response.data.data.utilities.sidebar_counts
        this.organizationFeatures = response.data.data.utilities.organization_features
        this.userSettings = response.data.data.utilities.user_settings
        this.passwordChangeRequired = this.userSettings['password_change_required'] == true
        this.cashAdvanceEnabled = this.organizationFeatures['cash_advance'] == true
        this.lendingEnabled = this.organizationFeatures['lending'] == true
        this.organizationKind = response.data.data.utilities.kind
        this.organizationType = response.data.data.utilities.type
        this.isGovernment = this.organizationType === 'government'
        console.log(this.isGovernment)
      } catch (error) {
        console.log(error)
        return error
      }
    },
  },
})
